<script>
import { useMeta } from 'vue-meta'
import Menu from '@/components/Heineken0-0_Q1/Menu'

export default {
	name: "Heineken0-0Q1_list",
	data() {
		return {
		};
	},
	setup () {
		useMeta(
		{ 
			title: '喝海尼根0.0零酒精 隨時乾杯都可以',
			description: '2024/1/24-2/29指定通路，買海尼根0.0零酒精，登錄發票抽PS5極限飆速組、1:10RC遙控賽車組' 
		})		
	},
	components: {
		Menu
	},
	computed: {
	},
	watch: {
	},
	mounted() {
	},
	methods: {
	},
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
